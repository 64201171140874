import React from "react";
import Lobby from "../../componet/lobby/lobby";

function LobbyPage() {
  return (
    <div>
      <Lobby />
    </div>
  );
}

export default LobbyPage;
