import React, { useEffect, useState } from "react";
type EducationFormType = {
  Studes: string;
  University: string;
  startData: string;
  endDate: string;
  city: string;
  Discription: string;
};
type EducationFormProps = {
  educationForm: Array<EducationFormType>;
  setEducationForm: React.Dispatch<React.SetStateAction<EducationFormType[]>>;
};

function EducationForm({
  educationForm,
  setEducationForm,
}: EducationFormProps) {
  const [studes, setStudes] = useState<string>("");
  const [university, setUniversity] = useState<string>("");
  const [startData, setStartData] = useState<string>("");
  const [endDate, setEndData] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [discription, setDiscription] = useState<string>("");
  const [data, setData] = useState<EducationFormType[]>([]);
  const [fill, setFill] = useState<boolean>(false);
  const EducationFormData: EducationFormType = {
    Studes: studes,
    University: university,
    startData: startData,
    endDate: endDate,
    city: city,
    Discription: discription,
  };
  const handleStudyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStudes(e.target.value);
  };
  const handleUniversityChange =   (e: React.ChangeEvent<HTMLInputElement>) => {
    setUniversity(e.target.value);
  };
  const handleStartDataChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartData(e.target.value);
  };
  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndData(e.target.value);
  };
  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCity(e.target.value);
  };
  const handleDiscriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDiscription(e.target.value);
  };

  const handleAdd = () => {
    if (
      studes.trim() !== "" &&
      university.trim() !== "" &&
      startData.trim() !== "" &&
      endDate.trim() !== "" &&
      city.trim() !== "" &&
      discription.trim() !== ""
    ) {
      // Update the parent component's educationForm state with the new data
      setFill(false)
      setEducationForm([
        ...educationForm,
        {
          Studes: studes,
          University: university,
          startData,
          endDate,
          city,
          Discription: discription,
        },
      ]);

      setStudes("");
      setUniversity("");
      setStartData("");
      setEndData("");
      setCity("");
      setDiscription("");
    }else{
      setFill(true)
    }
  };

  console.log(educationForm);
  return (
    <div>
      <div className="w-full bg-white p-10">
        <h1
          tabIndex={0}
          role="heading"
          aria-label="profile information"
          className="focus:outline-none text-3xl font-bold text-gray-800 mt-12"
        >
          Education
        </h1>
        <p
          role="contentinfo"
          className=" focus:outline-nonetext-sm font-light leading-tight text-gray-600 mt-4"
        >
          Add all The information About Your Studes Or Training
        </p>
        <div className="flex justify-center">
          <div className="mt-8 md:flex items-center">
            <div className="flex flex-col">
              <label className="mb-3 text-sm leading-none text-gray-800">
                Studes
              </label>
              <input
                type="Studes"
                tabIndex={0}
                aria-label="Enter first name"
                className="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
                value={studes}
                onChange={handleStudyChange}
              />
            </div>
            <div className="flex flex-col md:ml-12 md:mt-0 mt-8">
              <label className="mb-3 text-sm leading-none text-gray-800">
                University
              </label>
              <input
                type="University"
                tabIndex={0}
                aria-label="Enter last name"
                value={university}
                onChange={handleUniversityChange}
                className="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="mt-8 md:flex items-center">
            <div className="flex flex-col">
              <label className="mb-3 text-sm leading-none text-gray-800">
                Start Date
              </label>
              <input
                name="StartDate"
                type="date"
                tabIndex={0}
                aria-label="Enter date of birth"
                value={startData}
                onChange={handleStartDataChange}
                className="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
              />
            </div>
            <div className="flex flex-col md:ml-12 md:mt-0 mt-8">
              <label className="mb-3 text-sm leading-none text-gray-800">
                End Date
              </label>
              <input
                name="EndDate"
                type="date"
                tabIndex={0}
                value={endDate}
                onChange={handleEndDateChange}
                aria-label="Enter date of birth"
                className="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="mt-8 md:flex items-center">
            <div className="flex flex-col">
              <label className="mb-3 text-sm leading-none text-gray-800">
                City
              </label>
              <input
                type="name"
                name="City"
                tabIndex={0}
                value={city}
                onChange={handleCityChange}
                aria-label="Enter first name"
                className="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
              />
            </div>
            <div className="flex flex-col md:ml-12 md:mt-0 mt-8">
              <label className="mb-3 text-sm leading-none text-gray-800">
                Discription
              </label>
              <input
                name="Discription"
                type="name"
                value={discription}
                onChange={handleDiscriptionChange}
                tabIndex={0}
                aria-label="Enter last name"
                className="w-64 bg-gray-100 text-sm font-medium leading-none text-gray-800 p-3 border rounded border-gray-200"
              />
            </div>
          </div>
        </div>
        <br /> <br />
        <button
          onClick={handleAdd}
          type="button"
          className="text-gray-900 bg-gradient-to-r from-lime-200 via-lime-400 to-lime-500 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-lime-300 dark:focus:ring-lime-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
        >
          {" "}
          Add & save
         
        </button><br />
        {fill &&( <span className="text-rose-500">please fill the form properly</span>)}
        {educationForm.map((item, index) => (
          <li>{item?.Studes}</li>
        ))}
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n          .checkbox:checked + .check-icon {\n              display: flex;\n          }\n      ",
        }}
      />
    </div>
  );
}

export default EducationForm;
